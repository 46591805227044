body {
  margin: 0;
  font-family: 'BootsSharp', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight:400;
  line-height:1.4;
  box-sizing: border-box;
}

p, div, h3, h4 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.swiper-slide{
  display: flex;
  justify-content: center;
}